var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"flex-grow-1 align-content-center pb-5"},[_c('div',{staticClass:"container h-100"},[_vm._m(0),_c('h1',{staticClass:"d-block d-lg-none mt-4"},[_vm._v("Settings")]),_c('div',{staticClass:"d-flex h-100 mt-0 mt-lg-5 "},[(_vm.selectedAccount && _vm.selectedAccount.etsyData)?_c('div',{staticClass:"panel mx-auto my-auto"},[_c('div',{},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",attrs:{"id":"email","aria-describedby":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}})]),_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"form-label",attrs:{"for":"billing"}},[_vm._v("Billing details")]),(_vm.selectedAccount.subscription.type == 'free')?_c('div',[_c('div',{staticClass:"d-flex"},[_c('button',{staticClass:"btn btn-custom",on:{"click":_vm.setupStripe}},[_vm._v("Set up")]),_vm._m(2)]),_c('div',{staticClass:"mt-2 d-flex"},[_c('div',{staticClass:"d-inline-block",staticStyle:{"width":"310px"}},[_vm._v(" "+_vm._s((_vm.selectedAccount.subscription.printQuota.remaining))+" remaining this month ")]),_c('div',{staticClass:"progress w-100 my-auto"},[_c('div',{staticClass:"progress-bar",class:{
                                        'bg-success': _vm.printQuotaUsedPercentage > 0,
                                        'bg-warning': _vm.printQuotaUsedPercentage > 0.5,
                                        'bg-danger': _vm.printQuotaUsedPercentage >= 0.8
                                    },style:({ 'width': (_vm.printQuotaUsedPercentage * 100) + '%'}),attrs:{"role":"progressbar","aria-valuenow":_vm.selectedAccount.subscription.printQuota.remaining,"aria-valuemin":0,"aria-valuemax":_vm.selectedAccount.subscription.printQuota.start}})])])]):_c('div',{staticClass:"d-flex"},[_c('button',{staticClass:"btn btn-custom",on:{"click":_vm.manageStripe}},[_vm._v("Manage")]),_c('div',{staticClass:"ms-2 my-auto"},[_vm._v("You have an active plan")])])]),_c('div',{staticClass:"mt-3"},[_c('label',{staticClass:"form-label"},[_vm._v("Template")]),_c('div',{staticClass:"templates"},_vm._l((_vm.templates),function(ref){
                                    var id = ref.id;
                                    var thumbnail = ref.thumbnail;
                                    var name = ref.name;
return _c('div',{key:id,staticClass:"template",class:{ selected: _vm.selectedTemplate == id },on:{"click":function($event){_vm.selectedTemplate = id}}},[_c('img',{attrs:{"src":thumbnail,"alt":name + ' thumbnail'}}),_c('div',{staticClass:"mx-auto"},[_vm._v(_vm._s(name))])])}),0)]),_c('div',{staticClass:"mt-3"},[_c('label',{staticClass:"form-label"},[_vm._v("Template Customization")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.templateCustomizations.showShippingAddress),expression:"templateCustomizations.showShippingAddress"}],staticClass:"form-check-input mt-0",attrs:{"type":"checkbox","aria-label":"Show shipping address"},domProps:{"checked":Array.isArray(_vm.templateCustomizations.showShippingAddress)?_vm._i(_vm.templateCustomizations.showShippingAddress,null)>-1:(_vm.templateCustomizations.showShippingAddress)},on:{"change":function($event){var $$a=_vm.templateCustomizations.showShippingAddress,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.templateCustomizations, "showShippingAddress", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.templateCustomizations, "showShippingAddress", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.templateCustomizations, "showShippingAddress", $$c)}}}}),_c('span',{},[_vm._v("Show shipping address")])])]),_c('div',{staticClass:"d-flex justify-content-center mt-3"},[_c('div',[_c('button',{staticClass:"btn btn-pill btn-custom",on:{"click":_vm.save}},[_vm._v("Save")])])])]):_c('div',[_vm._v(" Please create a shop on Etsy to configure your account ")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-none d-lg-block ",staticStyle:{"position":"absolute","width":"0"}},[_c('h1',{staticClass:"one-word-per-line"},[_vm._v("Settings")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex "},[_c('label',{staticClass:"form-label",attrs:{"for":"email"}},[_vm._v("Printer Email Address ")]),_c('a',{staticClass:"ms-4",attrs:{"href":"#","data-toggle":"tooltip","title":"Some tooltip text!"}},[_c('u',[_vm._v("Not got a printer email?")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-auto",staticStyle:{"margin-left":"10px"}},[_c('a',{attrs:{"href":"#"}},[_vm._v("Already a customer?")])])}]

export { render, staticRenderFns }