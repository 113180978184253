<template>
    <main class="flex-grow-1 align-content-center pb-5">
        <div class="container h-100">
            <div style="position: absolute; width: 0;" class="d-none d-lg-block ">
                <h1 class="one-word-per-line">Settings</h1>
            </div>
            <h1 class="d-block d-lg-none mt-4">Settings</h1>
            
            <div class="d-flex h-100 mt-0 mt-lg-5 ">
                <div v-if="selectedAccount && selectedAccount.etsyData" class="panel mx-auto my-auto">
                    <div class="">
                        <div class="d-flex ">
                            <label for="email" class="form-label">Printer Email Address </label>
                            <a href="#" class="ms-4" data-toggle="tooltip" title="Some tooltip text!"><u>Not got a printer email?</u></a>
                        </div>
                        <input class="form-control" id="email" aria-describedby="email" v-model="email" />
                    </div>

                    <div class="mt-3">
                        <div for="billing" class="form-label">Billing details</div>
                        
                        <div v-if="selectedAccount.subscription.type == 'free'"> 
                            <div class="d-flex" >
                                <button class="btn btn-custom" @click="setupStripe">Set up</button>
                                <div class="my-auto" style="margin-left:10px;"><a href="#">Already a customer?</a></div>
                            </div>
                            <div class="mt-2 d-flex">
                                <div class="d-inline-block" style="width: 310px;">
                                    {{ (selectedAccount.subscription.printQuota.remaining) }} remaining this month
                                </div>

                                <div class="progress w-100 my-auto">
                                    <div
                                        :class="{
                                            'bg-success': printQuotaUsedPercentage > 0,
                                            'bg-warning': printQuotaUsedPercentage > 0.5,
                                            'bg-danger': printQuotaUsedPercentage >= 0.8
                                        }" 
                                        class="progress-bar" 
                                        role="progressbar" 
                                        :style="{ 'width': (printQuotaUsedPercentage * 100) + '%'}" :aria-valuenow="selectedAccount.subscription.printQuota.remaining" :aria-valuemin="0" :aria-valuemax="selectedAccount.subscription.printQuota.start"></div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex" v-else>
                            <button class="btn btn-custom" @click="manageStripe">Manage</button>
                            <div class="ms-2 my-auto">You have an active plan</div>
                        </div>
                    </div>

                    <div class="mt-3">
                        <label class="form-label">Template</label>
                        
                        <div class="templates">
                            <div 
                                class="template" 
                                :class="{ selected: selectedTemplate == id }" v-for="{ id, thumbnail, name } in templates" 
                                :key="id"
                                @click="selectedTemplate = id"
                            >
                                <img :src="thumbnail" :alt="name + ' thumbnail'" />
                                <div class="mx-auto">{{ name }}</div>
                            </div>
                        </div>
                    </div> 

                    <div class="mt-3">
                        <label class="form-label">Template Customization</label>
                        <div>
                            <input v-model="templateCustomizations.showShippingAddress" class="form-check-input mt-0" type="checkbox" aria-label="Show shipping address" />
                            <span class="">Show shipping address</span>
                        </div>
                    </div>


                    <div class="d-flex justify-content-center mt-3">
                        <div><button class="btn btn-pill btn-custom" @click="save">Save</button></div>
                    </div>
                </div>
                <div v-else>
                    Please create a shop on Etsy to configure your account
                </div>
            </div>
        </div>
    </main>
</template>

<script>

import { mapGetters } from 'vuex';

import { stripeSetupSubscripion, stripeManageSubscription } from "../api";

import Swal from "sweetalert2";
Swal.mixin({ heightAuto: false, })

export default {
    data() {
        return {
            email: "",
            selectedTemplate: "etsy",
            templates: [
                { thumbnail: "https://placekitten.com/400/1200", name: "Etsy Default", id: "etsy" },
                // { thumbnail: "https://placekitten.com/400/1200", name: "Etsy Default (6x4\")", id: "etsy64" },
            ],
            templateCustomizations: {
                showShippingAddress: false,
            }
        }
    },
    async mounted() {
        console.log("Mounted management");
        if (this.selectedAccount) {
            this.email = this.selectedAccount.printerEmail || this.email;
            this.selectedTemplate = this.selectedAccount.template || this.selectedTemplate;
            this.templateCustomizations = this.selectedAccount.templateCustomizations || this.templateCustomizations;
        }
        try { await this.$store.dispatch('refreshShop'); } catch (e) {}
    },
    computed: {
        ...mapGetters(['selectedAccount']),
        printQuotaUsedPercentage() {
            return (this.selectedAccount.subscription.printQuota.remaining) / this.selectedAccount.subscription.printQuota.start
        },
    },
    watch: {
        selectedAccount() {
            console.log("Selected account changed");
            if (this.selectedAccount) {
                this.email = this.selectedAccount.printerEmail || this.email;
                this.selectedTemplate = this.selectedAccount.template || this.selectedTemplate;
                this.templateCustomizations = this.selectedAccount.templateCustomizations || this.templateCustomizations;
            }
        }
    },
    methods: {
        async setupStripe() {
            Swal.showLoading();
            try {
                console.log(`Getting stripe response`);
                let stripeResponse = await stripeSetupSubscripion();
                console.log(`Got stripe response`);
                window.location.href = stripeResponse.data.redirect_url;
            } catch (e) {
                Swal.fire({ title: "Error", icon: "error" });
            }
        },
        async manageStripe() {
            Swal.showLoading();
            try {
                console.log(`Getting stripe response`);
                let stripeResponse = await stripeManageSubscription();
                console.log(`Got stripe response`);
                window.location.href = stripeResponse.data.redirect_url;
            } catch (e) {
                Swal.fire({ title: "Error", icon: "error" });
            }
        },
        async save() {
            Swal.showLoading()
            try {
                await this.$store.dispatch('updateAccount', {
                    printerEmail: this.email,
                    template: this.selectedTemplate,
                    templateCustomizations: this.templateCustomizations,
                }); 
                Swal.close();
            } catch (e) {
                Swal.fire({ title: "Error", icon: "error", text: "Unable to save" })
            }
        }
    }
}
</script>

<style>

</style>